import './polyfill';
import './App';
import React from 'react';
import * as AppContext from './AppContext';
import Layout from './Components/Layout';
import useGlobalOutlets from './Hooks/useGlobalOutlets';

export function PageContainer(props) {
  useGlobalOutlets();
  return <Layout {...props}>{props.children}</Layout>;
}

export function AppRoot(props) {
  return <AppContext.Provider {...props}>{props.children}</AppContext.Provider>;
}
